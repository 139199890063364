<template>
  <div id="shipment-status" class="mt-4">
    <div class="card" title header-tag="header" footer-tag="footer">
      <div class="card-header">
        <div class="max-w-3xl mx-auto">
          <h2 class="text-4xl mb-4">Status for {{getFormat(shipmentStatus.TrackingNo)}} {{shipmentStatus.TrackingNo}}</h2>
          <p class="text-2xl font-bold text-blue-700">{{getLastStatus()}}</p>
          <p class>{{shipmentStatus.ResultDetails.PortOrigin}} &rarr; {{shipmentStatus.ResultDetails.PortDestination}}</p>
          <p>&#128197; Booked {{formatDate(shipmentStatus.ResultDetails.BookingDate)}}</p>
        </div>
      </div>
      <div class="card-body">
        <div id="movement-history" class="row p-3">
          <h5 class="pl-0">Movement history</h5>
          <ul class="timeline">
            <li v-for="(movement, index) in shipmentStatus.ResultDetails.MovementHistory" v-bind:key="index">
              <div class="ml-4">
                <div class="status-header">
                  <span>{{getStatus(movement).toUpperCase()}}</span>
                  <span class="float-right">{{formatDateTime(movement.DateTime)}}</span>
                </div>
                <div class="col-md-12 pl-0 pr-0">
                  <p class="status-detail mb-0">{{movement.Details}}</p>
                  <p v-if="movement.ShipmentCustomerDocumentNo != '' && movement.ShipmentCustomerDocumentNo != null" class="status-crn">[ Customer Reference No: {{movement.ShipmentCustomerDocumentNo}} ]</p>
                  <a class="show-proof" v-if="movement.ImageUrl != null" v-on:click="isShown = !isShown" href="javascript:void(0);">Show Proof of Delivery</a>
                  <div v-if="isShown">
                   <li v-for="(image, index) in movement.ImageUrl" v-bind:key="index">
                    <a v-bind:href="image" target="_blank"> <img class="pod-images pb-1" v-bind:src="image" /></a>
                  </li>                                                
                </div>
                </div>                
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  props: ["shipmentStatus"],
  data() {
    return {
      isShown: false
    };
  },
  created() {},
  methods: {
    formatDate(d) {
      return moment(d).format("MMM D YYYY");
    },
    formatDateTime(d) {
      return moment(d).format("MMM D YYYY h:mm A");
    },
    getStatus(m) {
      let status = "";
      switch (m.MovementType) {
        case "Received":
        case "Delivered":
          status = "Delivered";
          break;
        case "Out for Delivery":
        case "For Delivery":
          status = "Out for delivery";
          break;
        default:
          status = m.Location;
      }
      return status;
    },
    getLastStatus() {
      let status = "";
      if (this.shipmentStatus.ResultDetails.MovementHistory[0]) {
        let m = this.shipmentStatus.ResultDetails.MovementHistory[0];
        switch (m.MovementType) {
          case "Received":
          case "Delivered":
            status = "Delivered to consignee";
            break;
          case "Out for Delivery":
            status = "Out for delivery";
            break;
          case "Shipped":
          case "Arrived":
            status = "In transit";
            break;
          default:
            status = m.MovementType;
        }
      }
      return status;
    },
    getLastStatusDate() {
      let d = '';
      if (this.shipmentStatus.ResultDetails.MovementHistory[0]) {
        d = this.formatDate(this.shipmentStatus.ResultDetails.MovementHistory[0].DateTime);
      }
      return d;
    },
    getFormat(str){
      let format = "";
      let isnum = /^\d+$/.test(str);
      if (isnum) {
        format = "Shipment Id";
      }else{
        format = "Waybill";
      }
      return format;
    }
  },
};
</script>

<style lang="css" scoped>
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css";
#shipment-status {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
ul.timeline {
  list-style-type: none;
  position: relative;
  padding-left: 40px;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
.status-header {
  color: blue;
  font-size: 14px;
}
.status-detail {
  font-size: 14px;
  margin: 0;
}
.status-crn{
  font-size: 12px;
  color: rgb(49, 181, 218);
  margin: 0;

}
.last-status {
  font-weight: 400;
  font-size: 18px;
}
.shipment-info {
  font-size: 14px;
}
.info-label {
  color: black;
  font-size: 14px;
}
.info-text {
  color: grey;
}
.pod-images { 
 width: 350px;
 height: 250px;
}
.show-proof {
  font-size: 12px;
}

</style>