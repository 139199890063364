<template>
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div id="track-shipment" class="col-md-8 mt-5 mb-5">
        <div id="input-tracking">
          <h1>Track your shipment</h1>
          <div class="form-group mt-3 mb-0">
            <div class="input-group input-group-lg" id="tracking-number">
              <input
                type="text"
                class="form-control"
                v-model="trackString"
                placeholder="Enter waybill number"
                aria-describedby="waybillHelp"
                v-on:keyup.enter="findShipment"
              />
              <button class="btn btn-outline-primary btn-lg ml-2" v-on:click="findShipment">Track</button>
            </div>
            <small
              id="waybillHelp"
              class="form-text text-muted pl-2"
            >Maximum of 10 waybill numbers only, separated by commas.</small>
          </div>
        </div>
        <div class="text-center mt-5" v-if="loading">
          <div class="spinner-border" role="status" style="width: 3rem; height: 3rem;">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div id="tracking-details">
          <div v-for="shipment in trackingResponse" :key="shipment.TrackingNo">
            <div
              class="alert alert-info mt-3"
              v-if="!(shipment.ResultDetails)"
              role="alert"
            >{{getFormat(shipment.TrackingNo)}} {{shipment.TrackingNo}} not found.</div>
            <TrackShipmentStatus
              v-if="shipment.ResultDetails"
              v-bind:shipmentStatus="shipment"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import trackingService from "../service/TrackingService.js";
import TrackShipmentStatus from "./TrackShipmentStatus";
//import  'bootstrap/dist/css/bootstrap.css';

export default {
  name: "TrackShipment",
  components: {
    TrackShipmentStatus,
  },
  data() {
    return {
      trackString: "",
      trackingResponse: [],
      loading: false,
    };
  },
  props: {},
  created: function () {},
  watch: {},
  methods: {
    findShipment() {
      this.trackingResponse = [];
      this.loading = true;
      this.trackString = this.trackString.replace(" ", "");
      const apiUrl = `https://api.limasawa.fast.com.ph/api/Shipments/ShipmentTracker?documentno=${this.trackString}`;
      fetch(apiUrl, {
        method: "GET",
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          Token:
            "c3lzdGVtQGZhc3Rncm91cC5iaXo6MjhlNmQyYjUtMzgzMy00ZWQ3LWE4MWUtZTk3NzUyYjU2ZmY3dHNhZg==",
        },
      })
        .then((response) => {
          this.loading = false;
          if (response.ok) {
            response.json().then((res) => {
              this.trackingResponse = res;
              //console.log(res);
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    getFormat(str){
      let format = "";
      let isnum = /^\d+$/.test(str);
      if (isnum) {
        format = "Shipment Id";
      }else{
        format = "Waybill";
      }
      return format;
    }
  },
};
</script>

<style lang="css" scoped>
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css";
#track-shipment {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
</style>